import Link from "next/link";
import React from "react";

import Styles from "./Footer.styles";
import { FooterProps as Props } from "./Footer.types";
import Divider from "../Divider/Divider";
import CONSTANTS from "config/constants";
import { useTalkShop } from "contexts/talkShop/talkShop.context.hooks";
import useI18n from "hooks/useI18n";

import LogoFooterSVG from "../../../../public/assets/images/logo.svg";

const { WITH_TALK_SHOP, WITH_FOOTER_LINKS } = CONSTANTS.FEATURE_FLAGS;
const { WITH_FOOTER_LINKS_NEW_TAB } = CONSTANTS.FEATURE_FLAGS;

const Footer: React.FC<Props> = props => {
  const { className } = props;
  const t = useI18n();
  const { talkShopProvider } = useTalkShop();
  const targetPreference = WITH_FOOTER_LINKS_NEW_TAB ? "_blank" : "_self";

  /**
   * Render privacy and terms component.
   *
   * @since 1.0.0
   * @returns {JSX.Element} Privacy and terms component.
   */
  const renderPrivacyAndTerms = (): JSX.Element => {
    return (
      <div className="Footer__row Footer__bottom__privacy-and-terms">
        <Link href="/profile/terms/legal" passHref>
          <a
            className="Footer__bottom__privacy-and-terms__item"
            target={targetPreference}
            rel="noopener noreferrer"
          >
            {t.footer.termsAndConditions}
          </a>
        </Link>
        <Link href="/profile/terms/privacy" passHref>
          <a
            className="Footer__bottom__privacy-and-terms__item"
            target={targetPreference}
            rel="noopener noreferrer"
          >
            {t.footer.privacyPolicy}
          </a>
        </Link>
        {/* TODO: Uncomment when the section is available */}
        {/* <Link href="/frequent-questions" passHref>
          <a
            className="Footer__bottom__privacy-and-terms__item"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t.footer.faq}
          </a>
        </Link> */}
      </div>
    );
  };

  /**
   * Render copyright component.
   *
   * @since 1.0.0
   * @returns {JSX.Element}  Privacy copyright component.
   */
  const renderCopyright = (): JSX.Element => {
    return (
      <div className="Footer__row Footer__bottom__copyright">
        {t.common.copyright}
      </div>
    );
  };

  /**
   * Render developer component.
   *
   * @since 1.0.0
   * @returns {JSX.Element} Privacy developer component.
   */
  const renderDeveloper = (): JSX.Element => {
    return (
      <div className="Footer__row Footer__bottom--right Footer__bottom__developer">
        {t.footer.developer}&nbsp;
        <span className="Footer__bottom__developer-company">
          {t.common.company}
        </span>
      </div>
    );
  };

  if (talkShopProvider && WITH_TALK_SHOP) return null;

  return (
    <Styles className={`Footer ${className}`}>
      <div className="Footer__content">
        <div className="Footer__top">
          <div className="Footer__logo">
            <LogoFooterSVG />
          </div>
          {WITH_FOOTER_LINKS ? (
            <div className="Footer__row Footer__labels">
              <p className="Footer__labels__item">{t.footer.reservations}</p>
              <p className="Footer__labels__item--mini">1800-MIKOBE</p>
              <p className="Footer__labels__item--mini">02 2211 600</p>
            </div>
          ) : null}
        </div>
        <div className="Footer__row Footer__bottom">
          {renderPrivacyAndTerms()}
          <Divider className="Footer__divider" />
          {renderCopyright()}
          {renderDeveloper()}
        </div>
        <div className="Footer__row Footer__bottom Footer__bottom--large">
          <Divider className="Footer__divider" />
          <div className="Footer__bottom--large-content">
            <div className="Footer__bottom--left">
              {renderPrivacyAndTerms()}
              {renderCopyright()}
            </div>
            {renderDeveloper()}
          </div>
        </div>
      </div>
    </Styles>
  );
};

Footer.defaultProps = {
  className: ""
};

export default Footer;
